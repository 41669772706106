<template>
  <v-dialog v-model="dialog" width="550" persistent overlay-opacity="0.75">
    <v-card rounded="lg">
      <v-card-title v-show="step === 1" class="text-18">
        <span style="word-break: normal; overflow-wrap: break-word">
          Verificação de Segurança
        </span>
      </v-card-title>

      <v-card-text>
        <v-stepper v-model="step" elevation="0" class="transparent">
          <v-stepper-content step="1" class="pa-0">
            <p>
              Para finalizar o pagamento, precisamos confirmar algumas
              informações.
            </p>

            <v-form @submit.prevent v-model="valid">
              <h6 class="my-4">Confirme o Endereço de Faturamento</h6>

              <div v-if="!address" class="text-center">
                <PlaceSearch
                  placeholder="Busque o endereço de faturamento"
                  label="Endereço de faturamento"
                  :value="address"
                  @input:raw="formatAddress"
                  outlined
                  :delay="1000"
                  hint="Endereço cadastrado no cartão"
                  :hide-details="false"
                  :search-types="['address']"
                />

                <v-btn text x-small @click="address = {}">
                  Preencher endereço manualmente
                </v-btn>
              </div>
              <template v-else>
                <v-text-field
                  v-model="address.street"
                  label="Endereço"
                  hint="Endereço com número"
                  :disabled="loading"
                  outlined
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  dense
                />
                <v-text-field
                  v-model="address.street2"
                  label="Complemento"
                  :disabled="loading"
                  hint="Apartamento, bloco, etc"
                  outlined
                  dense
                />
                <v-text-field
                  v-model="address.city"
                  label="Cidade"
                  :disabled="loading"
                  outlined
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  dense
                />
                <div class="d-flex gap-4">
                  <v-text-field
                    v-model="address.state"
                    class="flex-1"
                    label="Estado"
                    hint="Sigla do estado (SP, RJ, PR, etc)"
                    :disabled="loading"
                    outlined
                    :rules="[
                      (v) => !!v || 'Campo obrigatório',
                      (v) =>
                        (v && v.length === 2) ||
                        'Sigla do estado com 2 caracteres',
                    ]"
                    dense
                  />
                  <v-text-field
                    v-model="address.country"
                    label="País"
                    class="flex-1"
                    hint="Sigla do país (BR, US, etc)"
                    :disabled="loading"
                    outlined
                    :rules="[
                      (v) => !!v || 'Campo obrigatório',
                      (v) =>
                        (v && v.length === 2) ||
                        'Sigla do país com 2 caracteres',
                    ]"
                    dense
                  />
                </div>
                <v-text-field
                  v-model="address.zipCode"
                  label="CEP"
                  :disabled="loading"
                  outlined
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  dense
                  type="tel"
                />
              </template>
            </v-form>

            <v-alert v-if="error" type="error">
              {{ error }}
            </v-alert>
          </v-stepper-content>
          <v-stepper-content step="2">
            <div>
              <v-scroll-y-transition leave-absolute hide-on-leave>
                <div
                  :key="verificationStep"
                  class="d-flex flex-column gap-2 text-center justify-center px-2 pt-4"
                  style="height: 270px"
                >
                  <v-icon size="60" left>
                    {{ verificationSteps[verificationStep].icon }}
                  </v-icon>
                  <h5 class="mb-0">
                    {{ verificationSteps[verificationStep].title }}
                  </h5>
                  <p
                    v-if="verificationSteps[verificationStep].description"
                    style="text-wrap: balance"
                  >
                    {{ verificationSteps[verificationStep].description }}
                  </p>
                </div>
              </v-scroll-y-transition>
            </div>
          </v-stepper-content>
        </v-stepper>
      </v-card-text>

      <v-card-actions v-if="step === 1">
        <v-btn text @click="close" :disabled="loading"> Cancelar </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          @click="verify"
          :disabled="!valid || !address || loading"
        >
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import TICKET from "@/services/app/ticket";
import PlaceSearch from "@/views/global/PlaceSearch.vue";
import generateDeviceFingerprint from "@/utils/deviceFingerprint";
import { mapGetters } from "vuex";
import getIp from "@/utils/getIp";

import { captureException } from "@sentry/vue";

const { VUE_APP_VERCEL_ENV } = process.env;

export default {
  components: {
    PlaceSearch,
  },
  data: () => ({
    dialog: false,
    step: 1,
    loading: false,
    error: null,
    valid: false,
    address: null,
    verificationStep: "IDLE",
    verificationSteps: {
      IDLE: {
        icon: "mdi-shield-check",
        title: "Verificação Necessária",
        description:
          "Para garantir a segurança do seu pagamento, precisamos que você realize algumas verificações de segurança.",
      },
      DEVICE_VERIFICATION: {
        icon: "mdi-cellphone-link",
        title: "Verificando seu Dispositivo",
        description:
          "Coletando informações do dispositivo para garantir a autenticidade do pagamento.",
      },
      PAYMENT_DATA: {
        icon: "mdi-text-box",
        title: "Coletando Dados do Pagamento",
        description:
          "Reunindo os detalhes do seu pedido e informações de pagamento.",
      },
      BANK_CONNECTION: {
        icon: "mdi-lock",
        title: "Estabelecendo Conexão Segura",
        description:
          "Criando uma conexão criptografada com sua instituição financeira para proteger seus dados.",
      },
      BANK_VERIFICATION: {
        icon: "mdi-bank",
        title: "Confirmando Dados Bancários",
        description:
          "Estamos verificando os dados do seu pedido com sua instituição financeira.",
      },
      PAYMENT_PROCESS: {
        icon: "mdi-credit-card",
        title: "Processando pagamento",
        description: "Finalizando o pagamento, aguarde um instante.",
      },
    },
    invisibleForm: false,
    scriptTag: null,
    cardErrosDictionary: {
      INVALID_NUMBER: "Número do cartão inválido",
      INVALID_SECURITY_CODE: "Código de segurança inválido",
      INVALID_EXPIRATION_MONTH: "Mês de vencimento inválido",
      INVALID_EXPIRATION_YEAR: "Ano de vencimento inválido",
      INVALID_PUBLIC_KEY: "Chave pública inválida",
      INVALID_HOLDER: "Nome do titular inválido",
    },
  }),
  methods: {
    open() {
      this.dialog = true;
      this.address = null;
      this.verificationStep = "IDLE";
      this.step = 1;
      this.loading = false;
      this.error = null;
      this.destroyForm();
    },
    formatAddress(data) {
      const street = [
        this.findAddressComponent(data.address_components, "route")?.long_name,
      ];
      const number = this.findAddressComponent(
        data.address_components,
        "street_number"
      )?.long_name;
      if (number) street.push(number);

      this.address = {
        name: data.name,
        street: street.join(", "),
        street2: this.findAddressComponent(
          data.address_components,
          "subpremise"
        )?.long_name,
        neighborhood: this.findAddressComponent(
          data.address_components,
          "sublocality"
        )?.long_name,
        city: this.findAddressComponent(
          data.address_components,
          "administrative_area_level_2"
        )?.long_name,
        state: this.findAddressComponent(
          data.address_components,
          "administrative_area_level_1"
        )?.short_name,
        country: this.findAddressComponent(data.address_components, "country")
          ?.short_name,
        zipCode: this.findAddressComponent(
          data.address_components,
          "postal_code"
        )?.long_name,
        lat: data.geometry.location.lat(),
        lng: data.geometry.location.lng(),
      };
    },
    findAddressComponent(data, componentName) {
      return data.find((item) => item.types.includes(componentName));
    },
    normalizeText(text) {
      return text
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/[^a-zA-Z0-9 ]/g, "");
    },
    async pay(threeDSData) {
      try {
        this.destroyForm();
        if (this.verificationStep === "PAYMENT_PROCESS") return;
        this.error = null;
        this.loading = true;
        this.verificationStep = "PAYMENT_PROCESS";
        const expiration = moment(this.card.expiration, "MM/YY");

        const { success, data } = await TICKET.pay(this.paymentId, {
          type: "CREDIT_CARD",
          card: {
            number: this.card.number.replace(/\D/g, ""),
            expiration: expiration.format("MM/YYYY"),
            securityCode: this.card.security_code,
            holder: {
              name: this.card.holder.name,
              tax_id: this.card.holder.tax_id.replace(/\D/g, ""),
            },

            installments: this.card.installments,
            bin: this.card.number.substring(0, 6),
            last4: this.card.number.substring(this.card.number.length - 4),
          },
          authentication: threeDSData,
        });

        if (!success) throw new Error(data.message);

        this.close();
        this.$emit("success", data.status);
      } catch (error) {
        this.$emit("update");
        this.error =
          error.message || "Erro ao processar pagamento, tente novamente";
        this.loading = false;
        this.step = 1;
      }
    },
    async verify() {
      try {
        this.error = null;
        this.loading = true;
        this.step = 2;

        // Get Device Fingerprint
        this.verificationStep = "DEVICE_VERIFICATION";
        // const deviceFingerprint = await generateDeviceFingerprint();
        const deviceFingerprint = "unknown";
        const ipData = await getIp();
        await this.sleep(1000);

        // Get Payment Data
        this.verificationStep = "PAYMENT_DATA";
        const paymentData = await this.getPaymentData(
          deviceFingerprint,
          ipData.ip
        );
        this.destroyForm();
        this.invisibleForm = this.createInvisibleForm(paymentData);

        // Get Bank Connection
        this.verificationStep = "BANK_CONNECTION";
        await this.loadScript();
        await this.sleep(500);

        // Get Bank Verification
        this.verificationStep = "BANK_VERIFICATION";
        window.bpmpi_authenticate();
      } catch (error) {
        captureException(error, {
          tags: { module: "3ds" },
          extra: {
            paymentId: this.paymentId,
            cardBin: this.card?.number?.replace(/\D/g, "").substring(0, 6),
          },
        });

        this.error = error.message;
        this.loading = false;
        this.step = 1;
      }
    },
    processReturn(type) {
      if (type === "success") return (data) => this.pay(data);
      if (type === "failure")
        return (data) => {
          this.error =
            "Erro ao confirmar o cartão, por favor verifique os dados e tente novamente";
          this.loading = false;
          this.step = 1;
          this.destroyForm();
        };
      if (type === "unenrolled")
        return (data) => {
          this.error =
            "Seu cartão não é aceito para esse pagamento, tente novamente com outro cartão";
          this.loading = false;
          this.step = 1;
          this.destroyForm();
        };
      if (type === "disabled")
        return (data) => {
          this.error =
            "Seu cartão não é aceito para o pagamento, tente novamente com outro cartão";
          this.loading = false;
          this.step = 1;
          this.destroyForm();
        };
      if (type === "error")
        return (data) => {
          console.log(data);
          this.error = "Erro ao processar pagamento, tente novamente";
          this.loading = false;
          this.step = 1;
          this.destroyForm();
          captureException(data, {
            tags: { module: "3ds" },
            extra: {
              paymentId: this.paymentId,
              cardBin: this.card?.number?.replace(/\D/g, "").substring(0, 6),
            },
          });
        };
      if (type === "unsupported_brand")
        return (data) => {
          this.error =
            "Seu cartão não é aceito para o pagamento, tente novamente com outro cartão";
          this.loading = false;
          this.step = 1;
          this.destroyForm();
        };
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    destroyForm() {
      if (this.invisibleForm) this.invisibleForm.remove();
      this.invisibleForm = null;
    },
    async loadScript() {
      return new Promise((resolve, reject) => {
        if (this.scriptTag) this.scriptTag.remove();

        window.bpmpi_config = undefined;
        window.bpmpi_authenticate = undefined;

        this.scriptTag = document.createElement("script");
        this.scriptTag.src = "/scripts/BP.Mpi.3ds20.min.js";
        this.scriptTag.async = true;

        document.body.appendChild(this.scriptTag);

        window.bpmpi_config = () => ({
          onReady: () => resolve(),
          onSuccess: this.processReturn("success"),
          onFailure: this.processReturn("failure"),
          onUnenrolled: this.processReturn("unenrolled"),
          onDisabled: this.processReturn("disabled"),
          onError: this.processReturn("error"),
          onUnsupportedBrand: this.processReturn("unsupported_brand"),
          Environment: this.environment,
        });

        this.scriptTag.onerror = () => reject();
      });
    },
    async getPaymentData(deviceFingerprint, ip) {
      const token = await this.getToken();
      const amount = this.installments.find(
        (i) => i.value === this.card.installments
      ).price;
      const expiration = moment(this.card.expiration, "MM/YY");

      return {
        bpmpi_accesstoken: token,
        bpmpi_auth: "true",
        bpmpi_auth_suppresschallenge: "false",
        bpmpi_ordernumber: this.payment.id,
        bpmpi_currency: "BRL",
        bpmpi_totalamount: amount * 100,
        bpmpi_installments: this.card.installments,
        bpmpi_paymentmethod: "Credit",
        bpmpi_cardnumber: this.card.number.replace(/\D/g, ""),
        bpmpi_cardexpirationmonth: expiration.format("MM"),
        bpmpi_cardexpirationyear: expiration.format("YYYY"),
        bpmpi_order_productcode: "PHY",
        bpmpi_transaction_mode: "S",
        bpmpi_merchant_url: window.location.href,
        bpmpi_billto_customerid: this.card.holder.tax_id.replace(/\D/g, ""),
        bpmpi_billto_contactname: this.card.holder.name,
        bpmpi_billto_phonenumber: `${this.user.ddi} ${this.user.phone}`.replace(
          /\D/g,
          ""
        ),
        bpmpi_billto_email: this.user.email,
        bpmpi_billto_street1: this.address.street,
        bpmpi_billto_street2: this.address.street2,
        bpmpi_billto_city: this.normalizeText(this.address.city),
        bpmpi_billto_state: this.normalizeText(this.address.state),
        bpmpi_billto_zipcode: this.address.zipCode,
        bpmpi_billto_country: this.normalizeText(this.address.country),
        bpmpi_billto_neighborhood: this.normalizeText(
          this.address.neighborhood
        ),
        bpmpi_useraccount_guest: "false",
        bpmpi_useraccount_createddate: moment(this.user.createdAt).format(
          "YYYY-MM-DD"
        ),
        bpmpi_useraccount_changeddate: moment(this.user.updatedAt).format(
          "YYYY-MM-DD"
        ),
        bpmpi_device_ipaddress: ip,
        // bpmpi_device_1_fingerprint: deviceFingerprint,
        bpmpi_device_channel: "Browser",
        ...this.formatTickets(),
      };
    },
    formatTickets() {
      const groupedTickets = this.tickets.reduce((acc, ticket, index) => {
        if (!acc[ticket.TicketBlock.id]) acc[ticket.TicketBlock.id] = [];
        acc[ticket.TicketBlock.id].push(ticket);
        return acc;
      }, {});

      return Object.values(groupedTickets).reduce((acc, tickets, index) => {
        const prefix = `bpmpi_cart_${index + 1}_`;
        const ticket = tickets[0];
        return {
          ...acc,
          [prefix + "name"]: this.normalizeText(
            `${ticket.TicketBlock.TicketGroup.name} - ${ticket.TicketBlock.name}`
          ),
          [prefix + "quantity"]: tickets.length,
          [prefix + "unitprice"]: parseInt(ticket.amount * 100),
          [prefix + "description"]: this.normalizeText(this.party.name),
        };
      }, {});
    },
    async getToken() {
      const response = await TICKET.payVerify(this.paymentId);
      return response.data.access_token;
    },
    createInvisibleForm(params) {
      // Criação do formulário invisível
      var form = document.createElement("form");
      form.style.display = "none";
      document.body.appendChild(form);

      // Função para criar e adicionar um campo invisível ao formulário
      function addInvisibleField(name, value) {
        var input = document.createElement("input");
        input.type = "text";
        input.className = name; // Define a classe conforme a documentação
        input.value = value || "";
        form.appendChild(input);
      }

      // Itera sobre as propriedades do objeto params e adiciona os campos
      for (var key in params) {
        if (params.hasOwnProperty(key)) {
          addInvisibleField(key, params[key]);
        }
      }

      return form;
    },
    close() {
      this.dialog = false;
      this.$emit("close");
    },
  },

  computed: {
    ...mapGetters("auth", ["user"]),
    installments() {
      return this.payment.fees.map((fee) => {
        return {
          text: `${fee.installment}x`,
          value: fee.installment,
          interestFree: fee.interestFree,
          interestValue: fee.value,
          price: fee.total,
        };
      });
    },
    environment() {
      const env = VUE_APP_VERCEL_ENV || "development";
      if (env !== "production") return "SDB";
      return "PRD";
    },
  },

  watch: {
    "card.expiration": {
      handler: function (value) {
        if (!value) return;

        if (value.length === 1 && value > 1)
          this.card.expiration = `0${value}/`;

        if (value.length === 2 && value > 12) this.card.expiration = "12/";
      },
      immediate: true,
    },
    "address.state": {
      handler: function (value) {
        if (!value) return;
        this.address.state = value.toUpperCase();
      },
      immediate: true,
    },
    "address.country": {
      handler: function (value) {
        if (!value) return;
        this.address.country = value.toUpperCase();
      },
      immediate: true,
    },
  },

  mounted() {
    this.$root.$on("3ds-verification", this.open);
  },
  beforeDestroy() {
    this.destroyForm();
    this.$root.$off("3ds-verification", this.open);
  },

  props: {
    card: {
      type: Object,
      default: () => ({}),
    },
    paymentId: {
      type: String,
      required: true,
    },
    payment: {
      type: Object,
      required: true,
    },
    party: {
      type: Object,
      required: true,
    },
    tickets: {
      type: Array,
      default: () => [],
    },
    integrationData: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss">
#Cardinal-Modal {
  border-radius: 0.5rem;
  max-width: calc(100vw - 2rem);
}

#Cardinal-CCA-IFrame {
  max-width: calc(100vw - 2rem - 40px) !important;

  @media (max-width: 425px) {
    max-width: calc(100vw - 5rem) !important;
  }
}

#Cardinal-ModalContent {
  height: min(calc(100vh - 4rem), 550px) !important;
  max-height: 100%;
  iframe {
    height: 100%;
  }
}
</style>
